@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  tr {
    @apply hover:bg-gray-50;
  }

  input {
    @apply disabled:cursor-not-allowed;
  }
}

@layer components {
  .heading5 {
    @apply font-semibold text-[24px] text-[#1F2937];
  }

  .header {
    @apply text-3xl font-light;
  }

  .inputLabel {
    @apply text-xl;
  }

  .primaryButton {
    @apply bg-primaryColor px-9 py-3 text-white rounded-md;
  }
  .primaryButton-outline {
    @apply border-[1.5px] px-4 py-3 text-primaryColor border-primaryColor rounded-md;
  }

  .transparentButton {
    @apply px-9 py-3 text-white bg-transparent;
  }

  .redButton {
    @apply bg-danger px-9 py-2 text-white;
  }

  .greenButton {
    @apply bg-success px-9 py-2 text-white;
  }

  .addButton {
    @apply text-primaryColor flex items-center px-8 py-3 text-lg border-2;
  }

  .tableHead {
    @apply text-primaryColor text-start px-5 py-5 bg-[#F9FAFB];
  }

  .tableData {
    @apply text-start text-[14px] h-[60px] px-5 cursor-pointer;
  }

  .tableDataActionContainer {
    @apply flex justify-start space-x-2;
  }

  .tableActionContainer {
    @apply p-2 bg-[#E2E8F0] rounded-md cursor-pointer;
  }

  .tableEditIcon {
    @apply text-primaryColor h-5;
  }

  .tableDeleteIcon {
    @apply h-5 text-red-500;
  }

  .green-badge {
    @apply text-green-700 bg-green-200;
  }

  .gray-badge {
    @apply text-gray-700 bg-gray-300;
  }

  .pagination {
    @apply flex items-center justify-between w-20 gap-5 mt-5;
  }
  /*  */
  .prevPageLink {
    @apply bg-primaryColor px-9 py-3 text-white rounded-md select-none;
  }

  .nextPageLink {
    @apply bg-primaryColor px-9 py-3 text-white rounded-md select-none;
  }
  .dangerPill {
    @apply border-danger text-danger bg-dangerLight px-3 py-1 text-sm font-medium border-2 rounded-lg;
  }
  .successPill {
    @apply border-success text-success bg-successLight px-3 py-1 text-sm font-medium border-2 rounded-lg;
  }
}

#sidebar.sidebar ::-webkit-scrollbar {
  width: 7px;
}

/* Track */
#sidebar.sidebar ::-webkit-scrollbar-track {
  background: #473aff;
}

/* Handle */
#sidebar.sidebar ::-webkit-scrollbar-thumb {
  background: #6f9aee;
}

/* Handle on hover */
#sidebar.sidebar ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
